
body {
  font-family: 'SF Compact Display', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 50px;
  pointer-events: none;
  position: absolute;
  /* left: -3vw; */
  top: 1vmin;
  /* opacity: 0.9; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
    animation: moveclouds 1.8s linear infinite, sideWays 2s ease-in-out infinite alternate;
  }
}

body {
  background-color: #282c34;
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  color: white;
}

.opaque {
  background-color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes moveclouds {
    0% {
        transform: translateY(1px);
    }
    25% {
        transform: translateY(-2px);
    }
    50% {
        transform: translateY(-8px);
    }
    75% {
        transform: translateY(-2px);
    }
    100% {
        transform: translateY(1px);
    }
}

@keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:10px;
    }
}
